import Section5 from ".";

const Hero5 = () => {
  return (
    <div className=" px-6  lg:px-20 ">
      <Section5 />
    </div>
  );
};

export default Hero5;
