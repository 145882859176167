import Wrapp_card from ".";

const Bg_Hero3 = () => {
  return (
    <div className="    py-10 relative lg:pb-0">
      <Wrapp_card />
    </div>
  );
};

export default Bg_Hero3;
